// TotemHealthLab.js
// Main component for Totem Health Lab with responsive design adjustments

import React from 'react';
import './TotemHealthLab.css';
import { FaBrain, FaLungs, FaVirus } from 'react-icons/fa';

const TotemHealthLab = () => {
  return (
    <div className="totem-container">
      {/* Team Section */}
      <section className="totem-team-section">
        <h1 className="totem-team-title">We Are a Diverse Team from Around the World</h1>
        <p className="totem-team-description">
          At Totem Health Lab, we are dedicated scientists committed to the fight against cancer and the advancement of humanity.
          Our passion and dedication drive us to develop innovative solutions that transform healthcare and save lives.
        </p>
        <div className="totem-team-image-container">
          <img
            src={`${process.env.PUBLIC_URL}/FotoTotemGroup.png`}
            alt="Totem Health Lab Team"
            className="totem-team-image"
          />
        </div>
      </section>

      {/* Features Section */}
      <section className="totem-features-section">
        <h2 className="totem-features-title">Ongoing Research</h2>
        <p className="totem-features-subtitle">
          We conduct scientific research to develop models that allow faster prediction of diseases such as cancer in early stages.
          We use different types of neural networks in conjunction with quantum circuits for our investigations.
        </p>
        <div className="totem-feature-grid">
          <div className="totem-feature-block">
            <FaLungs className="totem-feature-icon" style={{ color: "#FF5733" }} />
            <h3 className="totem-feature-title">Lung Cancer MRI Detection</h3>
            <p className="totem-feature-description">
              Utilizing quantum computing to enhance MRI analysis for early and accurate detection of lung cancer.
            </p>
          </div>
          <div className="totem-feature-block">
            <FaBrain className="totem-feature-icon" style={{ color: "#007AFF" }} />
            <h3 className="totem-feature-title">Cellular Topology Analysis</h3>
            <p className="totem-feature-description">
              Analyzing cellular data topology to identify leukemia-mutated cells through advanced neural network models.
            </p>
          </div>
          <div className="totem-feature-block">
            <FaVirus className="totem-feature-icon" style={{ color: "#28A745" }} />
            <h3 className="totem-feature-title">Automated Spectrogram</h3>
            <p className="totem-feature-description">
              Detecting lung sounds such as wheezes and crackles using automated spectrograms and common headphones.
            </p>
          </div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="totem-timeline-section">
        <h2 className="totem-timeline-title">Our Achievements</h2>
        <div className="totem-timeline">
          <div className="totem-timeline-item left">
            <div className="totem-timeline-content">
              <p className="totem-timeline-text">Google for Startups Winner 2020</p>
            </div>
          </div>
          <div className="totem-timeline-item right">
            <div className="totem-timeline-content">
              <p className="totem-timeline-text">Top 100 Global at Entrepreneurship World Cup 2021</p>
            </div>
          </div>
          <div className="totem-timeline-item left">
            <div className="totem-timeline-content">
              <p className="totem-timeline-text">Launch Academy Canada 2022</p>
            </div>
          </div>
          <div className="totem-timeline-item right">
            <div className="totem-timeline-content">
              <p className="totem-timeline-text">Dream Team Santander 2023</p>
            </div>
          </div>
          <div className="totem-timeline-item left">
            <div className="totem-timeline-content">
              <p className="totem-timeline-text">Members of AWG - NASA 2024</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TotemHealthLab;
// Responsive design adjustments implemented to match Auren.css