// App.js
// Main application routing including the new Support page

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/NavbarTop/Navbar'; // Barra de navegación minimalista
import Auren from './page/Auren/Auren'; // Página Auren
import TotemHealthLab from './page/TotemHealthLab/TotemHealthLab'; // Página Totem Health Lab
import Support from './page/Support/Support'; // Página Support
import Footer from './components/Footer/Footer'; // Importar el Footer

const App = () => {
  return (
    <Router>
      <div className="app-container">
        <Navbar /> {/* Barra de navegación siempre visible */}
        <div className="content-wrap">
          <Routes>
            <Route path="/" element={<Navigate to="/auren" />} /> {/* Redirigir a Auren */}
            <Route path="/auren" element={<Auren />} /> {/* Página Auren */}
            <Route path="/totem-health-lab" element={<TotemHealthLab />} /> {/* Página Totem Health Lab */}
            <Route path="/support" element={<Support />} /> {/* Página Support */}
            {/* Puedes agregar más rutas aquí */}
          </Routes>
        </div>
        <Footer /> {/* Footer visible en todas las páginas */}
      </div>
    </Router>
  );
};

export default App;
// Updated App.js to include Support route