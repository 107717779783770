// Support.js
import React, { useState } from 'react';
import './Support.css';

const Support = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  return (
    <div className="support-container">
      <h1 className="support-title">Support Should Be Simple</h1>
      <p className="support-subtitle">
        Each customer is unique, which is why support must be seamless and tailored to their individual needs.
      </p>
      <div className="support-image-container">
        <img
          src={`${process.env.PUBLIC_URL}/TotemSupport.png`}
          alt="Support illustration"
          className="support-image"
        />
      </div>
      <p className="support-subtitle">
        Write to us at <a href="mailto:info@totemhealthlab.org">info@totemhealthlab.org</a>.
      </p>
      <div className="support-legal-buttons">
        <button className="legal-button" onClick={() => setShowPrivacy(true)}>Privacy Policy</button>
        <button className="legal-button" onClick={() => setShowTerms(true)}>Terms of Service</button>
      </div>

      {showPrivacy && (
        <div className="modal-overlay" onClick={() => setShowPrivacy(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setShowPrivacy(false)}>X</button>
            <div className="modal-body">
              <pre className="legal-text">
{`PRIVACY POLICY
Last updated February 28, 2025

This Privacy Notice for TOTEM HEALTH LAB (“we”, “us”, or “our”), describes how and why we might access, collect, store, use, and/or share (“process”) your personal information when you use our services (“Services”), including when you:

- Visit our website at https://totemhealthlab.org/auren, or any website of ours that links to this Privacy Notice.
- Download and use our mobile application (Auren), or any other application of ours that links to this Privacy Notice.
- Use Auren - Spot cancer early. Auren is an advanced AI system designed to empower doctors to make faster, more accurate diagnoses. With cutting-edge technology, Auren detects early stages of lung and breast cancer, while providing automatic alerts for high-risk patients, enabling timely and life-saving interventions.
- Engage with us in other related ways, including any sales, marketing, or events.

Questions or concerns?
Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@totemhealthlab.org.

------------------------------------------------------------

SUMMARY OF KEY POINTS

This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.

What personal information do we process?
When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us (link removed).

Do we process any sensitive personal information?
Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.

Do we collect any information from third parties?
We do not collect any information from third parties.

How do we process your information?
We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information (link removed).

In what situations and with which types of parties do we share personal information?
We may share information in specific situations and with specific categories of third parties. Learn more about when and with whom we share your personal information (link removed).

How do we keep your information safe?
We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe (link removed).

What are your rights?
Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights (link removed).

How do you exercise your rights?
The easiest way to exercise your rights is by visiting info@totemhealthlab.org, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.

Want to learn more about what we do with any information we collect? Review the Privacy Notice in full (link removed).

TABLE OF CONTENTS

1. WHAT INFORMATION DO WE COLLECT?
2. HOW DO WE PROCESS YOUR INFORMATION?
3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
7. HOW LONG DO WE KEEP YOUR INFORMATION?
8. HOW DO WE KEEP YOUR INFORMATION SAFE?
9. DO WE COLLECT INFORMATION FROM MINORS?
10. WHAT ARE YOUR PRIVACY RIGHTS?
11. CONTROLS FOR DO-NOT-TRACK FEATURES
12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
13. TOTEM HEALTH LAB AND ARTIFICIAL INTELLIGENCE DEVELOPMENT
14. DO WE MAKE UPDATES TO THIS NOTICE?
15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?

------------------------------------------------------------

1. WHAT INFORMATION DO WE COLLECT?

Personal information you disclose to us

In Short: We collect personal information that you provide to us.

We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

Personal Information Provided by You  
The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:

- Email addresses

Sensitive Information  
We do not process sensitive information.

All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.

------------------------------------------------------------

Google API

Our use of information received from Google APIs will adhere to the Google API Services User Data Policy (link removed), including the Limited Use requirements (link removed).

------------------------------------------------------------

2. HOW DO WE PROCESS YOUR INFORMATION?

In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.

We process your personal information for a variety of reasons, depending on how you interact with our Services, including:

- To facilitate account creation and authentication and otherwise manage user accounts.
  We may process your information so you can create and log in to your account, as well as keep your account in working order.

- To deliver and facilitate delivery of services to the user.
  We may process your information to provide you with the requested service.

- To respond to user inquiries/offer support to users.
  We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.

- To send administrative information to you.
  We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.

- To save or protect an individual’s vital interest.
  We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.

------------------------------------------------------------

3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?

In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.

If you are located in the EU or UK, this section applies to you.

The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:

- Consent.
  We may process your information if you have given us permission to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent (link removed).

- Performance of a Contract.
  We may process your personal information when we believe it is necessary to fulfill our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.

- Legal Obligations.
  We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.

- Vital Interests.
  We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.

In legal terms, we are generally the data controller under European data protection laws of the personal information described in this Privacy Notice, since we determine the means and purposes of the data processing we perform. This Privacy Notice does not apply to the personal information we process as a data processor on behalf of our customers. In those situations, the customer we provide services to and with whom we have entered into a data processing agreement is the data controller responsible for your personal information, and we merely process your information on their behalf in accordance with your instructions. If you want to know more about our customers’ privacy practices, you should read their privacy policies and direct any questions you have to them.

------------------------------------------------------------

If you are located in Canada, this section applies to you.

We may process your information if you have given us specific permission to use your personal information for a specific purpose, or in situations where your permission can be inferred. You can withdraw your consent at any time.

In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:

- If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
- For investigations and fraud detection and prevention
- For business transactions provided certain conditions are met
- If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
- For identifying injured, ill, or deceased persons and communicating with next of kin
- If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
- If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
- If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
- If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
- If the collection is solely for journalistic, artistic, or literary purposes
- If the information is publicly available and is specified by the regulations

------------------------------------------------------------

4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?

In Short: We may share information in specific situations described in this section and/or with the following categories of third parties.

Vendors, Consultants, and Other Third-Party Service Providers
We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.

The categories of third parties we may share personal information with are as follows:

- AI Platforms
- Cloud Computing Services
- Data Analytics Services
- Data Storage Service Providers
- User Account Registration & Authentication Services
- Performance Monitoring Tools

We also may need to share your personal information in the following situations:

- Business Transfers.
  We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

------------------------------------------------------------

5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?

In Short: We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.

As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, AI Products). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.

Use of AI Technologies
We provide the AI Products through third-party service providers, including Google Cloud AI. As outlined in this Privacy Notice, your input, output, and personal information will be shared with and processed by these AI Service Providers to enable your use of our AI Products for purposes outlined in the section on legal bases for processing your personal information (link removed).

You must not use the AI Products in any way that violates the terms or policies of any AI Service Provider.

Our AI Products
Our AI Products are designed for the following functions:

- AI research
- AI insights
- AI predictive analytics

How We Process Your Data Using AI
All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data’s safety.

------------------------------------------------------------

6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?

In Short: We may transfer, store, and process your information in countries other than your own.

Our servers are located in the United States. If you are accessing our Services from outside the United States, please be aware that your information may be transferred to, stored by, and processed by us in our facilities and in the facilities of third parties with whom we may share your personal information (see section on sharing personal information, link removed) in the United States, and other countries.

If you are a resident in the European Economic Area, United Kingdom, or Switzerland, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this Privacy Notice and applicable law.

European Commission’s Standard Contractual Clauses:
We have implemented measures to protect your personal information, including by using the European Commission’s Standard Contractual Clauses for transfers of personal information between our group companies and between us and our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Data Processing Agreements that include Standard Contractual Clauses are available at https://cloud.google.com/terms/processor-addendum?hl=es-419. We have implemented similar appropriate safeguards with our third-party service providers and partners and further details can be provided upon request.

------------------------------------------------------------

7. HOW LONG DO WE KEEP YOUR INFORMATION?

In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.

We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.

When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.

------------------------------------------------------------

8. HOW DO WE KEEP YOUR INFORMATION SAFE?

In Short: We aim to protect your personal information through a system of organizational and technical security measures.

We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.

------------------------------------------------------------

9. DO WE COLLECT INFORMATION FROM MINORS?

In Short: We do not knowingly collect data from or market to children under 18 years of age.

We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at info@totemhealthlab.org.

------------------------------------------------------------

10. WHAT ARE YOUR PRIVACY RIGHTS?

In Short: Depending on your state of residence in the US or in some regions, such as the European Economic Area, United Kingdom, Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.

In some regions, you have certain rights under applicable data protection laws. These may include the right to request access and obtain a copy of your personal information, to request rectification or erasure, to restrict the processing of your personal information, to data portability if applicable, and not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us using the contact details provided in the section on contacting us (link removed).

We will consider and act upon any request in accordance with applicable data protection laws.

If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority (link removed).

If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner (link removed).

Withdrawing your consent
If we are relying on your consent to process your personal information, which may be express or implied depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us using the contact details provided in the section on contacting us (link removed).

However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.

Account Information
If you would at any time like to review or change the information in your account or terminate your account, you can:

- Log in to your account settings and update your user account.

Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with investigations, enforce our legal terms and/or comply with applicable legal requirements.

If you have questions or comments about your privacy rights, you may email us at info@totemhealthlab.org.

------------------------------------------------------------

11. CONTROLS FOR DO-NOT-TRACK FEATURES

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (DNT) feature or setting you can activate to signal your privacy preference to not have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.

California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.

------------------------------------------------------------

12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?

In Short: If you are a resident of certain U.S. states, you may have rights that allow you greater access to and control over your personal information.

Categories of Personal Information We Collect
We have collected the following categories of personal information in the past twelve (12) months:

Category: A. Identifiers
Examples: Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
Collected: YES

Category: B. Personal Information as defined in the California Customer Records statute
Examples: Name, contact information, education, employment, employment history, and financial information
Collected: YES

Category: C. Protected classification characteristics under state or federal law
Examples: Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data
Collected: NO

Category: D. Commercial information
Examples: Transaction information, purchase history, financial details, and payment information
Collected: NO

Category: E. Biometric information
Examples: Fingerprints and voiceprints
Collected: NO

Category: F. Internet or other similar network activity
Examples: Browsing history, search history, online behavior, interest data, and interactions with websites, applications, systems, and advertisements
Collected: NO

Category: G. Geolocation data
Examples: Device location
Collected: NO

Category: H. Audio, electronic, sensory, or similar information
Examples: Images and audio, video or call recordings created in connection with our business activities
Collected: NO

Category: I. Professional or employment-related information
Examples: Business contact details to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
Collected: NO

Category: J. Education Information
Examples: Student records and directory information
Collected: NO

Category: K. Inferences drawn from collected personal information
Examples: Inferences drawn to create a profile or summary about preferences and characteristics
Collected: NO

Category: L. Sensitive personal information
Examples: None
Collected: NO

We may also collect other personal information outside these categories when you interact with us in person, online, or by phone/mail in the context of:
- Receiving help through our customer support channels.
- Participation in customer surveys or contests.
- Facilitation of Services and responding to inquiries.

Retention of Information
We will retain the collected personal information as necessary for the purposes outlined in this Privacy Notice, including:

- Category A - As long as the user has an account with us.
- Category B - As long as the user has an account with us.
- Categories C to J - As long as the user has an account with us.

Sources of Personal Information
Learn more about the sources of personal information we collect in the section on what information do we collect (link removed).

How We Use and Share Personal Information
Learn more about how we use your personal information in the section on how do we process your information (link removed).

Will your information be shared with anyone else?
We may disclose your personal information with service providers under a written contract. This is not considered a sale of your personal information.
We have disclosed the following categories to third parties in the past 12 months for business or commercial purposes:
- Category A: Identifiers.
- Category C: Protected classification characteristics.
- Category D: Commercial information.
- Category E: Biometric information.
- Category F: Internet or similar network activity.
- Category G: Geolocation data.
- Category H: Audio, electronic, or similar information.
- Category I: Professional or employment-related information.
- Category J: Education information.

Your Rights
You have rights under certain U.S. state data protection laws, including:
- Right to know if we are processing your personal data.
- Right to access your personal data.
- Right to correct inaccuracies in your personal data.
- Right to request the deletion of your personal data.
- Right to obtain a copy of your data.
- Right to non-discrimination for exercising your rights.

Depending on your state of residence, additional rights may include:
- Right to access categories of personal data processed.
- Right to obtain a list of third parties to whom data has been disclosed.
- Right to opt out of the processing of your personal data for specific purposes.

How to Exercise Your Rights
To exercise your rights, contact us at info@totemhealthlab.org. Verification may be required to confirm your identity.

California Shine The Light Law
California Civil Code Section 1798.83 allows California residents to request information about categories of personal data shared with third parties for direct marketing purposes in the past calendar year. To make such a request, contact us at info@totemhealthlab.org.

------------------------------------------------------------

13. TOTEM HEALTH LAB AND ARTIFICIAL INTELLIGENCE DEVELOPMENT

Totem Health Lab is committed to the research and development of artificial intelligence models, ensuring the implementation of best practices in all its processes. Our mission includes creating innovative solutions that adhere to the highest ethical and privacy standards, prioritizing the protection of user data and rights at every stage of our work.

------------------------------------------------------------

14. DO WE MAKE UPDATES TO THIS NOTICE?

In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

We may update this Privacy Notice from time to time. The updated version will be indicated by an updated Revised date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.

------------------------------------------------------------

15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?

If you have questions or comments about this notice, you may email us at info@totemhealthlab.org or contact us by post at:

TOTEM HEALTH LAB
48 West George Street
Office 2/3
Glasgow G2 1BP
Scotland

If you are a resident in the United Kingdom, we are the data controller of your personal information. We have appointed Totem Health Lab to be our representative in the UK. You can contact them directly regarding our processing of your information by email at info@totemhealthlab.org, by visiting https://totemhealthlab.org/totem-health-lab, by phone at +44 7449 491061, or by post to:

48 West George Street
2nd Floor, Office 2/3
Glasgow G2 1BP
Scotland

------------------------------------------------------------

16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?

You have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please visit: info@totemhealthlab.org
`}
              </pre>
            </div>
          </div>
        </div>
      )}

      {showTerms && (
        <div className="modal-overlay" onClick={() => setShowTerms(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setShowTerms(false)}>X</button>
            <div className="modal-body">
              <pre className="legal-text">
{`TERMS OF SERVICE

Last updated March 01, 2025

AGREEMENT TO OUR LEGAL TERMS

We are TOTEM HEALTH LAB LTD ("Company", "we", "us", or "our"), a company registered in Scotland at 48 West George Street, 2nd Floor, Office 3., Glasgow G2 1BP.

We operate, as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").

Auren is an advanced AI system designed to empower doctors to make faster, more accurate diagnoses. With cutting-edge technology, Auren detects early stages of lung and breast cancer, while providing automatic alerts for high-risk patients, enabling timely and life-saving interventions.

You can contact us by email at info@totemhealthlab.org or by mail to 48 West George Street, 2nd Floor, Office 3., Glasgow G2 1BP, Scotland.

These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and TOTEM HEALTH LAB LTD, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.

Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the Last updated date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.

The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.

We recommend that you print a copy of these Legal Terms for your records.

TABLE OF CONTENTS

1. OUR SERVICES  
2. INTELLECTUAL PROPERTY RIGHTS  
3. USER REPRESENTATIONS  
4. USER REGISTRATION  
5. PURCHASES AND PAYMENT  
6. SUBSCRIPTIONS  
7. SOFTWARE  
8. PROHIBITED ACTIVITIES  
9. USER GENERATED CONTRIBUTIONS  
10. CONTRIBUTION LICENCE  
11. GUIDELINES FOR REVIEWS  
12. SERVICES MANAGEMENT  
13. PRIVACY POLICY  
14. TERM AND TERMINATION  
15. MODIFICATIONS AND INTERRUPTIONS  
16. GOVERNING LAW  
17. DISPUTE RESOLUTION  
18. CORRECTIONS  
19. DISCLAIMER  
20. LIMITATIONS OF LIABILITY  
21. INDEMNIFICATION  
22. USER DATA  
23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES  
24. CALIFORNIA USERS AND RESIDENTS  
25. MISCELLANEOUS  
26. ADDITIONAL CONSIDERATIONS  
27. CONTACT US

------------------------------------------------------------

1. OUR SERVICES

The information provided when using the Services is not intended for distribution or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.

The Services are not tailored to comply with industry-specific regulations (for example, HIPAA or FISMA), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).

------------------------------------------------------------

2. INTELLECTUAL PROPERTY RIGHTS

Our intellectual property

We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").

Our Content and Marks are protected by copyright and trademark laws and other intellectual property rights and unfair competition laws and treaties in the United States and around the world.

The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use or internal business purpose only.

Your use of our Services

Subject to your compliance with these Legal Terms, including the PROHIBITED ACTIVITIES section below, we grant you a non-exclusive, non-transferable, revocable licence to:
- access the Services; and
- download or print a copy of any portion of the Content to which you have properly gained access,
solely for your personal, non-commercial use or internal business purpose.

Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.

If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@totemhealthlab.org. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.

We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.

Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.

------------------------------------------------------------

3. USER REPRESENTATIONS

By using the Services, you represent and warrant that:
1. All registration information you submit will be true, accurate, current, and complete;
2. You will maintain the accuracy of such information and promptly update such registration information as necessary;
3. You have the legal capacity and you agree to comply with these Legal Terms;
4. You are not a minor in the jurisdiction in which you reside;
5. You will not access the Services through automated or non-human means, whether through a bot, script or otherwise;
6. You will not use the Services for any illegal or unauthorized purpose; and
7. Your use of the Services will not violate any applicable law or regulation.

If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).

------------------------------------------------------------

4. USER REGISTRATION

You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.

------------------------------------------------------------

5. PURCHASES AND PAYMENT

We accept the following forms of payment:
- Apple Mac Store

You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in US dollars.

You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.

We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.

------------------------------------------------------------

6. SUBSCRIPTIONS

Billing and Renewal

Your subscription will continue and automatically renew unless canceled. You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you cancel the applicable order. The length of your billing cycle is monthly.

Cancellation

All purchases are non-refundable. Cancel your subscription through the Apple Mac Store subscription portal. Your cancellation will take effect at the end of the current paid term. If you have any questions or are unsatisfied with our Services, please email us at info@totemhealthlab.org.

Fee Changes

We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.

------------------------------------------------------------

7. SOFTWARE

We may include software for use in connection with our Services. If such software is accompanied by an end user licence agreement (EULA), the terms of the EULA will govern your use of the software. If such software is not accompanied by a EULA, then we grant you a non-exclusive, revocable, personal, and non-transferable licence to use such software solely in connection with our Services and in accordance with these Legal Terms. Any software and any related documentation is provided "AS IS" without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.

------------------------------------------------------------

8. PROHIBITED ACTIVITIES

You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or approved by us.

As a user of the Services, you agree not to:

- Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
- Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
- Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.
- Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.
- Use any information obtained from the Services in order to harass, abuse, or harm another person.
- Make improper use of our support services or submit false reports of abuse or misconduct.
- Use the Services in a manner inconsistent with any applicable laws or regulations.
- Engage in unauthorised framing of or linking to the Services.
- Upload or transmit (or attempt to upload or transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
- Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
- Delete the copyright or other proprietary rights notice from any Content.
- Attempt to impersonate another user or person or use the username of another user.
- Upload or transmit (or attempt to upload or transmit) any material that acts as a passive or active information collection or transmission mechanism, including, without limitation, clear graphics interchange formats, 1x1 pixels, web bugs, cookies, or other similar devices.
- Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.
- Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.
- Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.
- Copy or adapt the Services’ software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
- Except as set forth in applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.
- Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorised script or other software.
- Use a buying agent or purchasing agent to make purchases on the Services.
- Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences.
- Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise.
- Sell or otherwise transfer your profile.

------------------------------------------------------------

9. USER GENERATED CONTRIBUTIONS

The Services do not offer users the ability to submit or post content.

------------------------------------------------------------

10. CONTRIBUTION LICENCE

You and the Services agree that we may access, store, process, and use any information and personal data that you provide in accordance with the Privacy Policy and your choices (including settings).

By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.

------------------------------------------------------------

11. GUIDELINES FOR REVIEWS

We may provide areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria:
1. You should have firsthand experience with the person or entity being reviewed;
2. Your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language;
3. Your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability;
4. Your reviews should not reference illegal activity;
5. You should not be affiliated with competitors if posting negative reviews;
6. You should not make any conclusions as to the legality of conduct;
7. You may not post any false or misleading statements; and
8. You may not organize a campaign encouraging others to post reviews, whether positive or negative.

We may accept, reject, or remove reviews in our sole discretion. We have no obligation to screen or delete reviews, even if they are considered objectionable or inaccurate. Reviews are not endorsed by us and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you grant us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right to reproduce, modify, translate, transmit, display, perform, and distribute all content relating to reviews.

------------------------------------------------------------

12. SERVICES MANAGEMENT

We reserve the right, but not the obligation, to:
1. Monitor the Services for violations of these Legal Terms;
2. Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including reporting such user to law enforcement authorities;
3. In our sole discretion, refuse, restrict access to, limit the availability of, or disable any of your contributions or any portion thereof;
4. In our sole discretion, without limitation, notice, or liability, remove from the Services or disable all files and content that are excessive in size or otherwise burdensome to our systems; and
5. Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.

------------------------------------------------------------

13. PRIVACY POLICY

We care about data privacy and security. Please review our Privacy Policy at https://totemhealthlab.org/auren. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the United States. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from those in the United States, you are transferring your data to the United States and expressly consent to such transfer and processing.

------------------------------------------------------------

14. TERM AND TERMINATION

These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, AT OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR NO REASON, INCLUDING FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.

If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of that third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including pursuing civil, criminal, and injunctive redress.

------------------------------------------------------------

15. MODIFICATIONS AND INTERRUPTIONS

We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We will not be liable for any modification, price change, suspension, or discontinuance of the Services.

We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or supply any corrections, updates, or releases in connection therewith.

------------------------------------------------------------

16. GOVERNING LAW

These Legal Terms are governed by and interpreted in accordance with the laws of Scotland. The United Nations Convention on Contracts for the International Sales of Goods is expressly excluded. If your habitual residence is in the EU and you are a consumer, you additionally possess the protection provided by obligatory provisions of the law in your country of residence. Both TOTEM HEALTH LAB LTD and you agree to submit to the non-exclusive jurisdiction of the courts of Glasgow, meaning that you may make a claim to defend your consumer protection rights in Scotland or in the EU country in which you reside.

------------------------------------------------------------

17. DISPUTE RESOLUTION

Informal Negotiations
To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a "Dispute" and collectively, the "Disputes") brought by either you or us (each a "Party" and collectively, the "Parties"), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other.

Binding Arbitration
Any dispute arising from the relationship between the Parties under these Legal Terms shall be determined by one arbitrator chosen in accordance with the Arbitration and Internal Rules of the European Court of Arbitration, which is part of the European Centre of Arbitration and in force at the time the arbitration is filed. The seat of arbitration shall be Glasgow, Scotland. The language of the proceedings shall be English or Spanish. The applicable rules of substantive law shall be those of Scotland.

Restrictions
The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the fullest extent permitted by law:
(a) No arbitration shall be joined with any other proceeding;
(b) There is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and
(c) There is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.

Exceptions to Informal Negotiations and Arbitration
The Parties agree that the following Disputes are not subject to the provisions concerning informal negotiations and binding arbitration:
(a) Any Dispute seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party;
(b) Any Dispute related to or arising from allegations of theft, piracy, invasion of privacy, or unauthorized use; and
(c) Any claim for injunctive relief. If any portion of this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion, and such Dispute shall be decided by a court of competent jurisdiction in one of the jurisdictions listed above, with the Parties agreeing to submit to that court's personal jurisdiction.

------------------------------------------------------------

18. CORRECTIONS

There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.

------------------------------------------------------------

19. DISCLAIMER

The Services are provided on an AS-IS and AS-AVAILABLE basis. You agree that your use of the Services is at your sole risk. To the fullest extent permitted by law, we disclaim all warranties, express or implied, in connection with the Services and your use thereof, including the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranties or representations regarding the accuracy or completeness of the Services’ content or the content of any websites or mobile applications linked to the Services, and we assume no liability or responsibility for any:
1. Errors, mistakes, or inaccuracies of content and materials;
2. Personal injury or property damage of any nature resulting from your access to and use of the Services;
3. Unauthorized access to or use of our secure servers and/or any and all personal or financial information stored therein;
4. Interruption or cessation of transmission to or from the Services;
5. Bugs, viruses, Trojan horses, or similar items transmitted to or through the Services by any third party; and/or
6. Errors or omissions in any content or materials, or any loss or damage of any kind incurred as a result of using any content posted, transmitted, or otherwise made available via the Services.

We do not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Services, any hyperlinked website, or any website or mobile application featured in any banner or other advertising. We will not be a party to or responsible for any transaction between you and any third-party provider of products or services. As with any purchase, you should use your best judgment and exercise caution where appropriate.

------------------------------------------------------------

20. LIMITATIONS OF LIABILITY

In no event will we or our directors, employees, or agents be liable to you or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit, lost revenue, loss of data, or other damages arising from your use of the Services, even if we have been advised of the possibility of such damages. Notwithstanding any provision to the contrary, our liability to you for any cause whatsoever, regardless of the form of action, will at all times be limited to the amount paid, if any, by you to us during the six-month period prior to the claim. Certain state or international laws may not allow limitations on implied warranties or the exclusion or limitation of certain damages. If such laws apply, some or all of the disclaimers or limitations may not apply, and you may have additional rights.

------------------------------------------------------------

21. INDEMNIFICATION

You agree to defend, indemnify, and hold harmless us, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
1. Your use of the Services;
2. Your breach of these Legal Terms;
3. Your breach of any representations or warranties set forth in these Legal Terms;
4. Your violation of the rights of a third party, including intellectual property rights; or
5. Any harmful act toward any other user of the Services with whom you have connected.
Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of such claims. We will use reasonable efforts to notify you of any such claim upon becoming aware of it.

------------------------------------------------------------

22. USER DATA

We will maintain certain data that you transmit to the Services for managing the Services and data relating to your use of the Services. Although we perform regular backups, you are solely responsible for all data that you transmit or that relates to your activity using the Services. You agree that we shall have no liability to you for any loss or corruption of such data, and you waive any right to action against us arising from such loss or corruption.

------------------------------------------------------------

23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES

Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications and agree that all agreements, notices, disclosures, and other communications provided to you electronically, via email and on the Services, satisfy any legal requirement that such communications be in writing. YOU AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You waive any rights under any laws requiring an original signature or non-electronic record.

------------------------------------------------------------

24. CALIFORNIA USERS AND RESIDENTS

If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834 or by telephone at (800) 952-5210 or (916) 445-1254.

------------------------------------------------------------

25. MISCELLANEOUS

These Legal Terms and any policies or operating rules posted by us on the Services or in relation to the Services constitute the entire agreement between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms does not constitute a waiver of such right or provision. These Legal Terms operate to the fullest extent permitted by law. We may assign any or all of our rights and obligations to others at any time. We are not responsible for any loss, damage, delay, or failure to act caused by events beyond our control. If any provision of these Legal Terms is found to be unlawful, void, or unenforceable, that provision is deemed severable from the remaining provisions, which will remain in full force and effect. No joint venture, partnership, employment, or agency relationship is created by these Legal Terms or by your use of the Services. These Legal Terms will not be construed against us simply because we drafted them. You waive any defenses based on the electronic form of these Legal Terms or the lack of a signature.

------------------------------------------------------------

26. ADDITIONAL CONSIDERATIONS

By accessing and using the Totem Health Lab Ltd. application, you expressly agree to these terms and conditions. Totem Health Lab Ltd. holds exclusive ownership of all intellectual property rights over the material available on the application, and reproduction, redistribution, republication, sale, rental, or any other form of exploitation is strictly prohibited without express authorization. The Auren application is intended to assist healthcare professionals in analyzing large volumes of information, providing relevant data without issuing diagnoses. Any clinical or diagnostic decision must be made exclusively by a qualified healthcare professional, and Totem Health Lab Ltd. is not responsible for any consequences arising from the interpretation, use, or misuse of the data provided. Government agencies, search engines, and news organizations may link to the application without prior approval, while the creation of iFrames that alter the appearance of the application is permitted only with written authorization. Totem Health Lab Ltd. is not responsible for content on external sites that link to the application, and you agree to hold it harmless from any claims arising from such links. To the fullest extent permitted by law, Totem Health Lab Ltd. disclaims all warranties, representations, or conditions regarding the application and its use. If you disagree with any part of these terms, you must not use the application.

------------------------------------------------------------

27. CONTACT US

To resolve a complaint regarding the Services or to receive further information regarding the use of the Services, please contact us at:

TOTEM HEALTH LAB LTD
48 West George Street, 2nd Floor, Office 3.
Glasgow G2 1BP
Scotland
info@totemhealthlab.org
`}
              </pre>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default Support;