// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Email</h4>
          <p><a href="mailto:info@totemhealthlab.org">info@totemhealthlab.org</a></p>
        </div>
        <div className="footer-section">
          <h4>Telephone</h4>
          <p>UK: +44 7449 491061</p>
          <p>Col: +57 350 2354495</p>
        </div>
        <div className="footer-section">
          <h4>Location</h4>
          <p>Glasgow, Scotland.</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2025 Totem Health Lab Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

// Removed telephone links from Footer.js