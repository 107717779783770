// /Users/js/devAir/thlweb/thl/src/page/Auren/Auren.js
// Auren.js - Main component with image correction and additional section

import React from 'react';
import './Auren.css';
import { FaHeartbeat, FaUserMd, FaBell, FaRobot, FaApple, FaRocket, FaClipboardCheck } from 'react-icons/fa';

const Auren = () => {
  return (
    <div className="auren-container">
      <h1 className="auren-title">Professional tools for medical advancements</h1>
      <p className="auren-description">
        Auren is an advanced AI system designed to empower doctors to make faster, more accurate diagnoses. 
        With cutting-edge technology, Auren detects early stages of lung and breast cancer, while providing 
        automatic alerts for high-risk patients, enabling timely and life-saving interventions.
      </p>
      <div className="auren-image-container">
        <img
          src={`${process.env.PUBLIC_URL}/iMac-AurenHome.png`}
          alt="iMac showcasing Auren app"
          className="auren-image"
        />
      </div>
      <p className="auren-download-text">Available on the Mac App Store</p>
      <div className="auren-download-image-container">
        <img
          src={`${process.env.PUBLIC_URL}/Download_on_the_Mac_App_Store_Badge_US-UK_blk_092917.png`}
          alt="Download on the Mac App Store"
          className="auren-download-image"
        />
      </div>

      <section className="auren-new-section">
        <h2 className="auren-new-section-title">Key benefits of Auren</h2>
        <p className="auren-new-section-subtitle">
          Auren provides essential tools for early cancer detection, efficient patient management, 
          and timely alerts for high-risk individuals.
        </p>
        <div className="auren-feature-grid">
          <div className="auren-feature-block">
            <FaHeartbeat className="auren-feature-icon" style={{ color: "#FF5733" }} />
            <h3 className="auren-feature-title">Detect cancer early</h3>
            <p className="auren-feature-description">
              Early detection improves survival rates by enabling prompt treatments and reducing complications 
              in patients at high risk.
            </p>
          </div>
          <div className="auren-feature-block">
            <FaUserMd className="auren-feature-icon" style={{ color: "#007AFF" }} />
            <h3 className="auren-feature-title">Manage patients easily</h3>
            <p className="auren-feature-description">
              Streamlined tools minimize administrative tasks, allowing healthcare providers to dedicate 
              more time to caring for patients.
            </p>
          </div>
          <div className="auren-feature-block">
            <FaBell className="auren-feature-icon" style={{ color: "#FFC300" }} />
            <h3 className="auren-feature-title">Receive timely alerts</h3>
            <p className="auren-feature-description">
              Automated alerts enable quick actions for high-risk patients, helping prevent complications 
              and saving lives effectively.
            </p>
          </div>
          <div className="auren-feature-block">
            <FaRobot className="auren-feature-icon" style={{ color: "#28A745" }} />
            <h3 className="auren-feature-title">Leverage AI insights</h3>
            <p className="auren-feature-description">
              AI processes large datasets quickly, offering insights to improve diagnostic precision and decisions.
            </p>
          </div>
          <div className="auren-feature-block">
            <FaApple className="auren-feature-icon" style={{ color: "#000" }} />
            <h3 className="auren-feature-title">Exclusive for Mac</h3>
            <p className="auren-feature-description">
              Designed exclusively for macOS, Auren takes full advantage of Apple's robust ecosystem. 
            </p>
          </div>
          <div className="auren-feature-block">
            <FaRocket className="auren-feature-icon" style={{ color: "#FF4500" }} />
            <h3 className="auren-feature-title">Fast and intuitive</h3>
            <p className="auren-feature-description">
              Built for speed and simplicity, Auren is intuitive and easy to use from the very first interaction.
            </p>
          </div>
        </div>
      </section>

      <section className="auren-breast-section">
        <h2 className="auren-breast-section-title">Breast Cancer Risk</h2>
        <p className="auren-breast-section-subtitle">
          A powerful feature designed to assess breast cancer risk and provide actionable insights for doctors.
        </p>
        <div className="auren-breast-grid">
          <div className="auren-breast-image-container">
            <img
              src={`${process.env.PUBLIC_URL}/breastCancer.png`}
              alt="Breast Cancer Predict feature preview"
              className="auren-breast-image"
            />
          </div>
          <div className="auren-breast-block">
            <FaClipboardCheck className="auren-feature-icon" style={{ color: "#c6c6c6" }} />
            <h3 className="auren-breast-title">Advanced Risk Assessment</h3>
            <p className="auren-breast-description">
              Combines genetic, lifestyle, and medical data to deliver a comprehensive breast cancer risk analysis.
            </p>
          </div>
          <div className="auren-breast-block">
            <FaBell className="breast-feature-icon" style={{ color: "#c6c6c6" }} />
            <h3 className="auren-breast-title">Personalized Alerts</h3>
            <p className="auren-breast-description">
              Provides immediate feedback and recommendations for high-risk patients, empowering timely interventions.
            </p>
          </div>
        </div>
      </section>

      <div className="auren-container">
        <h1 className="auren-title">Advanced Cellular Analysis</h1>
        <p className="auren-description">
          The importance of having automatic tools for cell segmentation and identification of the nucleus and cell perimeter.
        </p>
        <div className="auren-image-container">
          <img
            src={`${process.env.PUBLIC_URL}/cellcontouring.png`}
            alt="Advanced Cellular Analysis feature preview"
            className="auren-image"
          />
        </div>
        <p className="auren-description">
        Identifying different cellular patterns quickly across thousands of images in seconds is a powerful tool for rapidly treating leukemia as well as rare diseases.        </p>
        
      </div>
    </div>
  );
};

export default Auren; 
// Added text below the last image