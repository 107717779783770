// Navbar.js
// Navigation bar with active links including the new Support route

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="navbar">
      <Link
        to="/auren"
        className={`navbar-item ${location.pathname === '/auren' ? 'active' : ''}`}
      >
        Auren
      </Link>
      <Link
        to="/totem-health-lab"
        className={`navbar-item ${location.pathname === '/totem-health-lab' ? 'active' : ''}`}
      >
        Totem Health Lab
      </Link>
      <Link
        to="/support"
        className={`navbar-item ${location.pathname === '/support' ? 'active' : ''}`}
      >
        Support
      </Link>
    </nav>
  );
};

export default Navbar;
// Updated Navbar.js to include Support link